@font-face {
  font-family: "Poppins";
  src: url("../font/Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../font/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../font/Poppins-Bold.ttf") format("truetype");
}

html {
  font-family: "Poppins", sans-serif;
}
body {
  margin: 0px;
  box-sizing: border-box;
}
.home {
  height: 100vh;
  margin-top: -1px !important;
  background: #fff2e7 0% 0% no-repeat padding-box;
}
.signin-img {
  background-image: url("../images/signin.png");
  background-size: cover;
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}
.signin-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form-container .form-title {
  text-align: center;
  font: normal normal normal 38px/57px Poppins;
  letter-spacing: 0px;
  color: #752525;
  opacity: 1;
  font-weight: bold;
}
.form-container .welcome-title {
  text-align: center;
  font: normal normal medium 32px/48px Poppins;
  letter-spacing: 0px;
  color: #242021;
  opacity: 1;
}
.form-container .welcome-title-desc {
  text-align: center;
  font: normal normal 300 16px/22px Poppins;
  letter-spacing: 0px;
  color: #242021;
  padding: 0px 65px;
  opacity: 0.58;
  font-weight: bold;
}
.form-container .form-fields {
  text-align: center;
}

.signin-input {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 12px #0000000f;
  border-radius: 20px !important;
  opacity: 1;
  height: 50px;
}
.signup-input {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 12px #0000000f;
  border-radius: 20px !important;
  opacity: 1;
  height: 50px;
}
.p {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  /* box-shadow: 0px 0px 12px #0000000f; */
  border-radius: 3px;
  border: 1px solid rgb(255, 152, 133);
  opacity: 1;
  height: 50px;
}
.signin-email-label[data-shrink="false"] {
  text-align: left;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #242021 !important;
  text-transform: capitalize;
  opacity: 0.57;
  font-weight: bold;
}
.signup-email-label[data-shrink="false"] {
  text-align: left;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #242021 !important;
  text-transform: capitalize;
  opacity: 0.57;
  font-weight: bold;
}
.signin-email-label[data-shrink="true"] {
  text-align: left;
  font: normal normal normal 13px/25px Poppins !important;
  letter-spacing: 0px;
  color: #242021 !important;
  text-transform: capitalize;
  opacity: 0.57;
}
.signup-email-label[data-shrink="true"] {
  text-align: left;
  font: normal normal normal 13px/25px Poppins !important;
  letter-spacing: 0px;
  color: #242021 !important;
  text-transform: capitalize;
  opacity: 0.57;
}
.signin-input:before,
.signup-input:before {
  border-bottom: transparent !important;
}
.signin-input:after,
.signup-input:after {
  border-bottom: transparent !important;
}
.signin-input-icon,
.signup-input-icon {
  height: 20px !important;
  fill: #c5c5c5;
}
.p:before {
  border-bottom: transparent !important;
}
.p:after {
  border-bottom: transparent !important;
}
.p-icon {
  height: 20px !important;
  fill: #c5c5c5;
}
.forget-link {
  text-align: right;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #242021;
  text-transform: capitalize;
  opacity: 0.57;
  text-decoration: none;
  font-weight: 600;
}
.text-right {
  text-align: right;
}
.button-signin,
.default-link {
  background: #ff9885 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
}
.form-bottom-link {
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #242021;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
}
.signin-bottom-link {
  text-decoration: underline;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #ff9885;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: bold;
}
.terms-label {
  text-align: left;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #242021;
  text-transform: capitalize;
  opacity: 0.57;
}
.btn-contain{
  max-width: 100px;
}
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.button-signup {
  background: #ff9885 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1;
  text-transform: uppercase;
  height: 40px;
}
.align-items-center {
  align-items: center;
}
.social-message {
  text-align: left;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #242021 !important;
  text-transform: capitalize;
  opacity: 0.57 !important;
}
.text-center {
  text-align: center;
}
.MuiDrawer-paper {
  background: #ff9885 0% 0% no-repeat padding-box !important;
}
.sidebar {
  display: block !important;
  padding: 25px 25px !important;
}
.sidebar-title {
  text-align: left;
  font: normal normal normal 20px/17px Poppins;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 1;
  font-weight: bold;
}
.item-active1 {
  background: #f3816c 0% 0% no-repeat padding-box !important;
  height: 50px !important;
}
.sidebar-list {
  position: relative;
  padding: 0px 8px;
  height: 50px !important;
}
.sidebar-list:hover {
  background: #f3816c 0% 0% no-repeat padding-box !important;
  height: 50px !important;
}

.triangle-right {
  position: absolute;
  width: 0;
  right: -10px;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 10px solid red;
  border-bottom: 20px solid transparent;
}

.drawer-button {
  position: absolute;
  left: -36px;
  height: 38px;
  top: -10px;
  /* width: 100%; */
}
.mobile-drawer-button {
  position: absolute;
  left: -36px;
  height: 1.9rem;
  top: -5px;
}
.bg-white {
  background-color: white !important;
}
.appbar-title {
  font: normal normal normal 30px/46px PoppinsSemiBold !important;
  letter-spacing: 0px !important;
  color: #4e3d39;
  opacity: 1;
}
.mobile-appbar-title {
  font: normal normal normal 1.2rem/46px PoppinsSemiBold !important;
  letter-spacing: 0px !important;
  color: #4e3d39;
  opacity: 1;
}
.app-bar {
  height: 80px;
}
.app-bar .toolbar {
  height: 80px;
}
.main {
  background-color: #f8f1f1 !important;
  min-height: 100vh;
}

.sidebar-link span {
  text-align: left;
  font: normal normal normal 13px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: bold;
}
.list-button {
  position: relative;
}
.list-button:hover {
  background-color: transparent !important;
}
.heading-bold {
  font: normal normal normal 24px/25px PoppinsBold !important;
  letter-spacing: 0px;
  color: #4e3d39;
  opacity: 1;
}
.heading-semi {
  font: normal normal 300 16px/25px Poppins !important;
  letter-spacing: 0px;
  color: #4e3d39;
  opacity: 1;
  font-weight: 600 !important;
}
.td-none {
  text-decoration: none;
}
.event-card-head {
  text-align: left;
  font: normal normal medium 16px/25px Poppins !important;
  letter-spacing: 0px;
  color: #4e3d39;
  font-weight: 500;
  opacity: 1;
}
.event-detail-link {
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #4e3d39;
  opacity: 1;
  font-weight: 600;
}
.event-detail-top-head {
  font: normal normal normal 15px/27px PoppinsSemiBold !important;
  letter-spacing: 0px;
  color: #4e3d39;
  opacity: 1;
  font-weight: 600 !important;
}
.event-detail-date {
  font: normal normal normal 12px/23px PoppinsSemiBold !important;
  letter-spacing: 0px;
  color: #828081;
  opacity: 1;
  font-weight: 400;
}
.event-detail-lower-desc {
  font: normal normal normal 14px/23px Poppins !important;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  font-weight: bold;
}
hr {
  border-top: 2px solid #707070;
  opacity: 0.12;
}
.default-button {
  font: normal medium normal 15px/23px Poppins !important;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  background: #ff9885 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  font-weight: 700;
}
.default-button2 {
  font: normal medium normal 15px/23px Poppins !important;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  background: #c5c2c2 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  font-weight: 700;
}
.event-card-list-head {
  font: normal normal normal 15px/23px PoppinsSemiBold !important;
  letter-spacing: 0px;
  color: #4e3d39;
  opacity: 1;
  font-weight: 400;
}
.bg-secondary {
  background: #fbfbfb 0% 0% no-repeat padding-box;
}
.event-list {
  margin-left: 0px !important;
}
.event-list-item {
  cursor: pointer;
}
.event-list-item #left {
  color: #828081;
  font-size: 12px;
  margin-right: 5px;
}
.event-list-item #right {
  border-left: 2px solid #f0f0f0;
  text-align: left;
  font: normal normal normal 16px/20px PoppinsBold;
  letter-spacing: 0px;
  color: #4e3d39;
  opacity: 1;
  padding-left: 5px;
}
.events-card {
  border-radius: 5px;
}
.events-add-link {
  background: #ff9885 0% 0% no-repeat padding-box;
  border-radius: 5px 0px 0px 0px;
  opacity: 1;
  cursor: pointer;
}
.justify-content-center {
  justify-content: center;
}

.event-tab-buttons button {
  border: 1px solid #ebebeb;
  font: normal normal normal 14px/18px Poppins;
  letter-spacing: 0px;
  color: #acacac;
  font-weight: 500;
  border-radius: 0px !important;
  padding: 8px 25px;
}
.event-tab-buttons button:hover {
  border: 1px solid #ff9885;
  background-color: #f3806c2e;
  font: normal normal normal 14px/18px Poppins;
  letter-spacing: 0px;
  color: #acacac;
}
.event-tab-buttons .active {
  border: 0px !important;
  background-color: #fda99a !important;
  font: normal normal normal 14px/18px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  font-weight: 500;
}
.event-tab-buttons .active:hover {
  border: 0px !important;
  background-color: #fff2e7 !important;
  font: normal normal normal 14px/18px Poppins;
  letter-spacing: 0px;
  color: #ff9885;
  font-weight: 500;
}
.event-search {
  height: 35px !important;
}
.event-table {
  border: 1px solid;
}
th,
td {
  border: 1px solid #dddddd !important;
}
th {
  padding: 10px !important;
  text-align: left;
  font: normal normal bold 12px/20px PoppinsBold !important;
  letter-spacing: 0px;
  color: #242934;
  opacity: 1;
  font-weight: bold !important;
  text-align: center !important;
}
.Chip-labelMedium,
.css-6od3lo-MuiChip-label {
  color: white !important;
}
td {
  color: #605f5f !important;
  font-size: 12px !important;
  padding: 10px 5px !important;
  text-align: center !important;
}
.action-buttons .view {
  border-radius: 5px;
  background-color: #37a000;
  border: 1px solid #37a000;
  padding: 3px 3px;
  cursor: pointer;
}
.action-buttons .edit {
  border-radius: 5px;
  background-color: #ffb61e;
  border: 1px solid #ffb61e;
  padding: 3px 3px;
  cursor: pointer;
}

.action-buttons .delete {
  cursor: pointer;
  border-radius: 5px;
  background-color: #e5343d;
  border: 1px solid #e5343d;
  padding: 3px 3px;
}
.action-buttons .filter {
  cursor: pointer;
  border-radius: 5px;
  background-color: #ff9885;
  border: 1px solid #ff9885;
  padding: 3px 3px;
}
.pagination {
  box-shadow: none !important;
}
.pagination button {
  background: #c5c5c5 0% 0% no-repeat padding-box;
  margin: 1px;
  color: white;
  font-weight: bold;
  border-right: none !important;
}
.pagination button:hover {
  background: #c5c5c5 0% 0% no-repeat padding-box;
  margin: 1px;
  color: white;
  font-weight: bold;
}
.pagination .disable {
  background: #fff2e7 0% 0% no-repeat padding-box;
  margin: 1px;
  font: normal normal normal 11px/17px PoppinsBold !important;
  letter-spacing: 0px;
  color: #ff9885;
  opacity: 1;
  cursor: default;
  font-weight: 600;
}
.pagination .disable:hover {
  background: #fff2e7 0% 0% no-repeat padding-box;
  margin: 1px;
  font: normal normal normal 11px/17px PoppinsBold !important;
  letter-spacing: 0px;
  color: #ff9885;
  opacity: 1;
  font-weight: 600;
}
.pagination .not-disable {
  background: #fff2e7 0% 0% no-repeat padding-box;
  margin: 1px;
  font: normal normal normal 11px/17px PoppinsBold !important;
  letter-spacing: 0px;
  border: 2px solid #ff9885;
  color: #ff9885;
  opacity: 1;
  cursor: default;
  font-weight: 600;
}
.pagination .not-disable:hover {
  background: #fff2e7 0% 0% no-repeat padding-box;
  margin: 1px;
  font: normal normal normal 11px/17px PoppinsBold !important;
  letter-spacing: 0px;
  color: #ff9885;
  opacity: 1;
  font-weight: 600;
}
.pagination .active {
  background: #ff9885 0% 0% no-repeat padding-box;
  margin: 1px;
  color: white;
  font-weight: bold;
  border-right: none !important;
}
.pagination .active:hover {
  background: #c5c5c5 0% 0% no-repeat padding-box;
  margin: 1px;
  color: white;
  font-weight: bold;
}
.create-card-steps {
  background: #ff9885 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
}
.white-border-box {
  background: #ffffff;
  height: 4px !important;
}
.m-0 {
  margin: 0px !important;
}
.w-100 {
  width: 100% !important;
}
.event-step {
  padding: 35px 0px !important;
}
.close-button {
  position: absolute;
  right: 0px;
  top: -5px;
  cursor: pointer;
}
.img-upload {
  font: normal normal normal 12px/18px Poppins !important;
  letter-spacing: 0px;
  color: #acacac !important;
  opacity: 1;
  border: 1px solid #acacac !important;
}
.img-upload:hover {
  background-color: #bfbcbc0f !important;
}
.add-task-button {
  background: #ff9885 0% 0% no-repeat padding-box;
  border-radius: 4px;
  height: 50px;
  cursor: pointer;
  margin-top: 25px;
  border: 1px solid #ff9885;
  padding: 10px 15px;
}
.divider {
  border-left: 1px solid grey;
}
.default-secondary {
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  background: #f8f1f1 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  font-weight: 700;

  font: normal normal medium 15px/23px Poppins;
  color: #4e3d39 !important;
  text-transform: uppercase;
  opacity: 0.4;
}
.create-event-field {
  background: #ededed !important;
  height: 50px !important;
}
.event-cover {
  position: relative;
}
.event-cover img {
  width: 100%;
  object-fit: cover;
  height: 80%;
  border-radius: 5px 5px 0px 0px !important;
}
.event-cover .side-buttons {
  text-align: center;
  height: 100%;
  background: #a9a9a99c;
  border-radius: 0px 5px 0px 0px;
  opacity: 1;
  top: -6px;
  right: 0;
  position: absolute;
  padding: 20px 30px !important;
}
.profile-counts {
  background: #c5c5c5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  padding: 7px 7px;
  height: 45px;
}
.gallery-card {
  background: #fdf1f1 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 10px;
}
.task-performers {
  border-left: 5px solid #efefef;
}
.task-participants-img {
  border-radius: 5px;
  width: 32px;
  height: 30px;
}
.default-button-light {
  font: normal medium normal 15px/23px Poppins !important;
  letter-spacing: 0px;
  color: #ff9885 !important;
  text-transform: uppercase;
  opacity: 1;
  background: #fff2e7 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  font-weight: 700;
}
.gallery-btns {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 5px;
}
.user-profile-img {
  width: 160px;
  height: 147px;
  border-radius: 9px;
  text-align: center;
}
.profile-table {
  margin: auto;
}
.swiper-horizontal > .swiper-pagination-bullets {
  display: none !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: url("../images/icon-next.png") !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: url("../images/icon-prev.png") !important;
}

.Mui-selected {
  background: #f3816c !important;
}
.cursor-password {
  cursor: pointer !important;
}
input[type="date"] {
  color: #979595 !important;
}
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaaaaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
.css-1rhbuit-multiValue {
  background-color: rgb(255, 152, 133) !important;
  color: white !important;
}
.Mui-checked {
  color: rgb(255, 152, 133) !important;
}
.Mui-checked + .MuiSwitch-track {
  background-color: rgb(255, 152, 133) !important;
}
.css-12jo7m5 {
  color: white !important;
}
::placeholder {
  color: #aaaaaa !important;
}
.pinkscroll::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}
.pinkscroll::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
.pinkscroll::-webkit-scrollbar-thumb {
  background-color: #f1a698cf; /* color of the scroll thumb */
  border-radius: 15px; /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
}

/* .MuiInputLabel-root {
  z-index: 0 !important;
} */
.MuiInputLabel-root.Mui-focused {
  color: rgb(255, 152, 133) !important
}
.MuiOutlinedInput-notchedOutline{
  border-color: rgb(255, 152, 133) !important;
}